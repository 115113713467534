import type { ParseKeys } from 'i18next';
import CarDamageIcon from '../assets/illustrations/car-damage.svg';
import CarMileageIcon from '../assets/illustrations/car-mileage.svg';
import CarPhotoIcon from '../assets/illustrations/car-photo.svg';
import CarFaultIcon from '../assets/illustrations/car-fault.svg';
import CarOwnershipIcon from '../assets/illustrations/car-ownership.svg';
import CarTheftIcon from '../assets/illustrations/car-theft.svg';

type Feature = {
  id: 'damage' | 'mileage' | 'photos' | 'faults' | 'owners' | 'theft' | 'other';
  Icon?: (props: React.SVGProps<SVGSVGElement>) => React.ReactElement;
  titleKey: `common:${ParseKeys}`;
  descriptionKey?: `home:${ParseKeys<'home'>}`;
  motoDescriptionKey?: `home:${ParseKeys<'home'>}`;
  universalDescriptionKey?: `home:${ParseKeys<'home'>}`;
};

const FEATURES: Feature[] = [
  {
    id: 'damage',
    Icon: CarDamageIcon,
    titleKey: 'common:features.damage.title',
    descriptionKey: 'home:featureDescriptions.damage.long',
    motoDescriptionKey: 'home:featureDescriptions.damage.moto',
    universalDescriptionKey: 'home:featureDescriptions.damage.universal',
  },
  {
    id: 'mileage',
    Icon: CarMileageIcon,
    titleKey: 'common:features.mileage.title',
    descriptionKey: 'home:featureDescriptions.mileage.long',
    motoDescriptionKey: 'home:featureDescriptions.mileage.moto',
    universalDescriptionKey: 'home:featureDescriptions.mileage.universal',
  },
  {
    id: 'photos',
    Icon: CarPhotoIcon,
    titleKey: 'common:features.photos.title',
    descriptionKey: 'home:featureDescriptions.photos.long',
    motoDescriptionKey: 'home:featureDescriptions.photos.moto',
    universalDescriptionKey: 'home:featureDescriptions.photos.universal',
  },
  {
    id: 'faults',
    Icon: CarFaultIcon,
    titleKey: 'common:features.faults',
    descriptionKey: 'home:featureDescriptions.faults.long',
    motoDescriptionKey: 'home:featureDescriptions.faults.moto',
    universalDescriptionKey: 'home:featureDescriptions.faults.universal',
  },
  {
    id: 'owners',
    Icon: CarOwnershipIcon,
    titleKey: 'common:features.owners',
    descriptionKey: 'home:featureDescriptions.owners.long',
    motoDescriptionKey: 'home:featureDescriptions.owners.moto',
    universalDescriptionKey: 'home:featureDescriptions.owners.universal',
  },
  {
    id: 'theft',
    Icon: CarTheftIcon,
    titleKey: 'common:features.theft',
    descriptionKey: 'home:featureDescriptions.theft.long',
    motoDescriptionKey: 'home:featureDescriptions.theft.moto',
    universalDescriptionKey: 'home:featureDescriptions.theft.universal',
  },
];

const BRITAIN_FEATURES = [
  'License plate change',
  'Write-off & scrap',
  'Market value',
  'Vehicle identity check',
  'Damage records',
  'Mileage history',
  'Theft records',
  'Outstanding finance',
  'Road tax',
  'MOT',
];

export { FEATURES, BRITAIN_FEATURES };
export type { Feature };
